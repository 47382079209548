import * as React from 'react';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Grid';
import Grow from '@mui/material/Grow';

export default function CardView(props) {
  const { topic, extraText, imgPath, imgTitle, viewText, technologiesUsed } = props;
  const ref = React.useRef(null);

  React.useEffect(() => {
    if (ref.current) {
      window.scrollTo(0, ref.current.getBoundingClientRect().top + window.scrollY - 200);
    }
  }, []);

  return (
        <Grow in={true} ref={ref}>
        <Grid 
        container spacing={4}>
        <Grid item xs={5}>
        <CardMedia
            sx={{ height: "600px", objectFit: "cover"}}
            elevation={0}
            src={imgPath}
            component="video"
            title={imgTitle}
            draggable={false}
            autoPlay
            loop="true"
        />
        </Grid>
        <Grid item xs={6.75}>
          
        <CardContent>
          <Typography
          sx={{fontSize: {
                  lg: 24,
                  md: 22,
                  sm: 20,
                  xs: 16
            }}}
            align='left' component="div" color="#0b5099">
            {topic}
          </Typography>
          <Typography          
          sx={{fontSize: {
                  lg: 14,
                  md: 13,
                  sm: 12,
                  xs: 12
            }}}
            fontStyle='italic' variant="body2" align='left' color="#0b5099">
            {extraText}
          </Typography><br></br>
          <Typography 
          sx={{fontSize: {
                      lg: 15,
                      md: 14,
                      sm: 13,
                      xs: 12
            }}} 
            variant="body1" align='left' color="#0b5099" textAlign={"justify"}>
            {viewText}
          </Typography><br></br><br></br>
          <Typography 
          sx={{fontSize: {
                      lg: 15,
                      md: 14,
                      sm: 13,
                      xs: 12
          }}}
          variant="body1" align='left' color="#0b5099">
          <b>Technologies used:</b> {technologiesUsed}
          </Typography>
        </CardContent>
        </Grid>
        </Grid></Grow>);
}