import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import BasicCard from '../card/BasicCard';
import CardView from '../cardView/CardView';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import './MainPage.css';
import { Fade, Link, Slide } from '@mui/material';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

function MainPage() {
  const [clicked, setClicked] = useState(0);
  const executeScroll = (n) => {
    setClicked(n)
    if (clicked == n) return
  }

  return (
    <div className="App">
      <Grid container justifyContent={'flex-end'} paddingTop={1} paddingRight={1} marginBottom={-1}>  
      <Fade timeout={{enter: 3000}} in={true}>
        <Link href="https://www.linkedin.com/in/christoph-mauritz-49b011308/" target="_blank">
          <LinkedInIcon fontSize='large' style={{fill: "white"}} />
        </Link>
      </Fade>
      </ Grid>
      <Grid height={visualViewport.height} item xs={12} style={{display:'flex', flexDirection: 'column', justifyContent: 'space-between'}} >
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={{ minHeight: '10%' }}
            style={{flexGrow:'1'}}
          >
            <Grid item xs={3}>
              <Slide timeout={{enter: 1000}} direction='right' in={true}>
                <Typography sx={{
                  fontSize: {
                    xl: 225,
                    lg: 200,
                    md: 133,
                    sm: 113,
                    xs: 75
                  }
                }} className='HeaderText' color="#00448d" variant="h1" marginTop={8}>
                          <b>Hello,</b> <br></br>
                </Typography>
                </Slide>
                <Slide timeout={{enter: 1500}} direction='right' in={true}>
                <Typography sx={{
                  fontSize: {
                    xl: 225,
                    lg: 200,
                    md: 133,
                    sm: 113,
                    xs: 75
                  }
                }} className='HeaderText' color="white" variant="h1">
                          I'm Chris
                </Typography>
                </Slide>
            </Grid>
          </Grid>
        <Grid>
          <Fade timeout={{enter: 3000}} in={true}>
          <div>
          <Typography sx={{
            fontSize: {
              lg: 32,
              md: 30,
              sm: 38,
              xs: 26
            }
            }} fontStyle='italic' color="white" marginBottom={-25}>
            Personal Projects
          </Typography>
          <ArrowDropDownIcon viewBox='0 0 24 12' className="DropdownArrow" onClick={() => document.getElementById("firstCard").scrollIntoView({ block: "center", behavior: 'smooth' })}/>
          </div>
          </Fade>
        </Grid>
    </Grid>
    <Box
      sx={{ml: "20%", mr: "20%", flexGrow: 1 }}>
      <Grid 
      container spacing={4}>
        <Grid item xs={12}>
          <Card id="firstCard"
            sx={{backgroundColor: "White"}}>
            <CardActionArea
              disableRipple
              onClick={(e) => {
                executeScroll(1)
              }}
            >
              {clicked == 1 
              ? <CardView imgPath="GamePic.mp4" imgTitle="Game Prototype" topic="2D Pixel Art Shooter" viewText={<div>The game is divied into two parts. In the first part the player has to complete 
              various dungeons, gather resources and fight enemies. <br></br><br></br>These resource are then used as ingredients to prepare various meals to customers in the second part of the game. The money
              that the customers pay is then used to upgrade the player which in turn makes them stronger and thus allows them to further go into the dungeon.<br></br><br></br>
              The game is currently in a pre-alpha state.</div>} extraText="Prototype of multiplayer rogue-lite made in Godot"
              technologiesUsed="Godot, GDScript, GLSL, Git, Aseprite"/>
              : <BasicCard imgPath="GamePic.jpg" imgTitle="Game Prototype" topic="2D Pixel Art Shooter" extraText="Prototype of multiplayer rogue-lite made in Godot"/>
              }
            </CardActionArea>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card
            sx={{backgroundColor: "White"}}>
            <CardActionArea
              disableRipple
              onClick={(e) => {
                executeScroll(2)
              }}
            >
              {clicked == 2 
              ? <CardView imgPath="SimpleShooter.mp4" imgTitle="Third person shooter made in Unreal Engine 5" topic="Third Person Prototype Shooter Made In Unreal Engine 5" viewText={<div>This was the final project of an Unreal Engine 5 course. 
                The game uses Behavior Trees for the enemy AI and Animation Blueprints as well as Animation State Machines to correctly display the player's and the enemy's movement.<br></br><br></br>If the player is visible from the enemy's point of view, then the enemy walks within
                range and starts shooting. If the player runs away, then the enemy follows him until he loses line of sight. When that happens the enemy investigates the last know position of the player and if he is still unable to find him, he then returns to his
                starting position.</div>} extraText="Made as the final project of an Unreal Engine 5 course"
              technologiesUsed="Unreal Engine 5, C++, Blueprints, EventGraph, Animation Blueprints, Animation State Machines, Behavior Tree System with custom C++ services and tasks"/>
              : <BasicCard imgPath="SimpleShooter.jpg" imgTitle="Third person shooter made in Unreal Engine 5" topic="Third Person Prototype Shooter Made In Unreal Engine 5" extraText="Made as the final project of an Unreal Engine 5 course"/>
              }
            </CardActionArea>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card
            sx={{backgroundColor: "White"}}>
            <CardActionArea
              disableRipple
              onClick={(e) => {
                executeScroll(3)
              }}
            >
              {clicked == 3
              ? <CardView imgPath="CryptRaider.mp4" imgTitle="Unreal Engine 5 prototype game" topic="Prototype Game Made In Unreal Engine 5" viewText={<div>This game was also made as part of an Unreal Engine 5 course. This game also uses a combination of the Blueprint system and C++ components. In this game the player traverses a dungeon and has to interact with different objects to find hidden passages. In order to realistically light the scene, Unreal Engine 5’s Lumen lighting system is used.</div>} extraText="Prototype made as part of an Unreal Engine 5 course"
              technologiesUsed="Unreal Engine 5, C++, Blueprints, EventGraph"/>
              : <BasicCard imgPath="CryptRaider.jpg" imgTitle="Unreal Engine 5 prototype game" topic="Prototype Game Made In Unreal Engine 5" extraText="Prototype made as part of an Unreal Engine 5 course"/>
              }
            </CardActionArea>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card
            sx={{backgroundColor: "White"}}>
            <CardActionArea
              disableRipple
              onClick={(e) => {
                executeScroll(4)
              }}
            >
              {clicked == 4
              ? <CardView imgPath="ToonTanks.mp4" imgTitle="Unreal Engine 5 Prototype Tank Game" topic="Unreal Engine 5 Prototype Tank Game" extraText="Prototype of a simple tank game made in Unreal Engine 5" viewText={<div>Game made as part of an Unreal Engine 5 course. The game uses a combination of the Blueprint system and C++ components. 
                In this game the player takes control over a tiny tank. That can move around the map and move its tower independently according to the mouse position. The goal is to destroy all the enemy turrets that attack the player by destroying them with your turret.<br></br><br></br>
                The game makes use of Unreal's particle and audio system to make the game more appealing. Unreal's widget system is used to display basic information to the player such as a start countdown and telling the player if they won or lost. </div>}
              technologiesUsed="Unreal Engine 5, C++, Blueprints, EventGraph, Unreal Widgets"/>
              : <BasicCard imgPath="ToonTanks.jpg" imgTitle="Unreal Engine 5 Prototype Tank Game" topic="Unreal Engine 5 Prototype Tank Game" extraText="Prototype of a simple tank game made in Unreal Engine 5"/>
              }
            </CardActionArea>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card
            sx={{backgroundColor: "White"}}>
            <CardActionArea
              disableRipple
              onClick={(e) => {
                executeScroll(5)
              }}
            >
              {clicked == 5
              ? <CardView imgPath="RendererPic.mp4" imgTitle="Custom 3D Real-Time Renderer" topic="Custom 3D Renderer" extraText="Sphere Tracing based renderer" viewText={<div>Custom 3D Renderer made as 
                part of my bachelor's thesis. The renderers uses a combination of signed distance functions and Hart's sphere tracing algorithm to render objects to the screen. This is in contrast to
                regular real-time renderes that use meshes made out of a collection of geometrical primitives (triangles in most cases) to render objects to the screen. <br></br><br></br>The different approach used
                for this renderers causes a lot of difficulties but also opens up many new possibilites for visual effects that are either hard to do in a regular renderer or that require a lot more work.
                For example this renderer can display "infinite" objects onto the screen, blend models together or produce high quality soft shadows. The renderer was also used to create a prototype 
                of the game Minecraft. 
              </div>} 
                technologiesUsed="LWJGL, OpenGL, Java, Compute Shaders, Docker, Git"/>
                : <BasicCard imgPath="RendererPic.jpg" imgTitle="Custom 3D Renderer" topic="Custom 3D Renderer" extraText="Sphere Tracing based renderer"/>
              }
            </CardActionArea>
          </Card>
        </Grid>        
        <Grid item xs={12}>
          <Card
            sx={{backgroundColor: "White"}}>
            <CardActionArea
              disableRipple
              onClick={(e) => {
                executeScroll(6)
              }}
            >
              {clicked == 6
              ? <CardView imgPath="RegularRenderer.mp4" imgTitle="Custom Conventional 3D Renderer" topic="Custom Conventional 3D Renderer" extraText="Renderer that uses conventional methods to render 3D objects" viewText={<div>A custom 3D Renderer that uses conventional methods to display objects. The renderer supports
                various basic features such as rendering multiple objects, adding different light sources that use the phong reflection model with customizable parameters and adding soft and regular shadows to the scene. <br></br><br></br>This renderer was developed as part of my bachelor's thesis in order to compare 
                this method of visualizing 3D objects with another one. The Renderer only supports the use of .obj files since this application was only used to compare its performance and ease of use while displaying 3D models. 
              </div>} 
                technologiesUsed="LWJGL, OpenGL, Java, Docker, Git"/>
                : <BasicCard imgPath="RegularRenderer.jpg" imgTitle="Custom Conventional 3D Renderer" topic="Custom Conventional 3D Renderer" extraText="Renderer that uses conventional methods to render 3D objects"/>
              }
            </CardActionArea>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card
            sx={{backgroundColor: "White"}}>
            <CardActionArea
              disableRipple
              onClick={(e) => {
                executeScroll(7)
              }}
            >
              {clicked == 7
              ? <CardView imgPath="TowerDefensePic.mp4" imgTitle="Tower Defense Game" topic="Tower Defense Game" extraText="Tower defense game made in Unity" viewText={<div>Created as
                part of a game jam for a course in university. The goal was to create a tower defense game with multiple enemies, a boss and upgradeable towers.<br></br><br></br>
                It was also an requirement to optimize the game in a way that allows it to run smoothly in a browser.
                <br></br><br></br>Source code: <a href="https://gitlab.com/chrosomauiini/towerdefensegame" target="_blank">Link</a>
              </div>} 
                technologiesUsed="Unity, C#"/>
                : <BasicCard imgPath="TowerDefensePic.jpg" imgTitle="Tower Defense Game" topic="Tower Defense Game" extraText="Tower defense game made in Unity"/>
              }
            </CardActionArea>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card
            sx={{backgroundColor: "White"}}>
            <CardActionArea
              disableRipple
              onClick={(e) => {
                executeScroll(8)
              }}
            >
              {clicked == 8
              ? <CardView imgPath="EndlessRunnerPic.mp4" imgTitle="Endless Runner Game" topic="Edless Runner Game" extraText="Made in Unity as part of a game development course in university" viewText={<div>Endless runner game
                that was made as part of a Unity course in university. Game includes an infinitely repeating level, obstacles and power ups.
                <br></br><br></br>Source code: <a href="https://gitlab.com/chrosomauiini/endlessrunnerunity" target="_blank">Link</a>
              </div>} 
                technologiesUsed="Unity, C#"/>
                : <BasicCard imgPath="EndlessRunnerPic.jpg" imgTitle="Edless Runner Game" topic="Edless Runner Game" extraText="Made in Unity as part of a game development course in university"/>
              }
            </CardActionArea>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card
            sx={{backgroundColor: "White"}}>
            <CardActionArea
              disableRipple
              onClick={(e) => {
                executeScroll(9)
              }}
            >
              {clicked == 9
              ? <CardView imgPath="BlenderTests.mp4" imgTitle="FPS animations" topic="First Person Animations" viewText={<div>In order to create 3D animations for future projects, Blender was used to learn and practice this skill.
                <br></br><br></br>Rig used: <a href="https://gamebanana.com/models/4307" target="_blank">Link</a>
                <br></br>Model used: <a href="https://sketchfab.com/3d-models/katana-with-sheath-932957e69501402cad6b5e20d710c97c" target="_blank">Link</a>
                </div>} extraText="3D animations made with Blender"
              technologiesUsed="Blender"/>
              : <BasicCard imgPath="BlenderTests.jpg" imgTitle="FPS animations" topic="First Person Animations" extraText="3D animations made with Blender"/>
              }
            </CardActionArea>
          </Card>
        </Grid>
        {clicked == 0 ? <div style={{display: "inline-block", height: "650px"}}></div> : <></>}
      </Grid>
    </Box>
    </div>
  );
}

export default MainPage;
